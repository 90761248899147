import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import SuccessfulDialog from "./SuccessfulDialog";
import { useDidMountEffect } from "../../../_helpers/_CommonUIHelpers";
import { Redirect } from "react-router-dom";
import { ErrorMessages } from "../../../../_bloomgoo/_partials/controls";
import { InformationRes } from "../../../../_bloomgoo/_partials/accordions/InformationRes";
import Recaptcha from 'react-google-invisible-recaptcha';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const initialValues = {
  organization: {
    name: "",
    code: "",
    taxNumber: "",
    address: "",
    provinceId: 0,
    districtId: 0,
    wardId: 0,
  },
  user: {
    firstName: "",
    lastName: "",
    fullName: "",
    password: "",
    username: "",
    email: "",
    gender: 1,
    birthday: 0,
    tel: "",
    confirmPassword: "",
    isAcceptedTerms: false,
  },
};

function Registration(props) {
  const { intl } = props;

  const refRecaptcha = React.useRef(null);
  const [recaptchaValid, setRecaptchaValid] = useState(false);

  const [isSuccessFulDialog, setSuccessFulDialog] = useState(false);
  const [isGoSignIn, setIsGoSignIn] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const RegistrationSchema = Yup.object().shape({
    organization: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage({
          id: "AUTH.ORGANIZATION.REQUIRED_FIELD",
        })
      ),

      address: Yup.string().required(
        intl.formatMessage({
          id: "AUTH.ADDRESS.REQUIRED_FIELD",
        })
      ),
      code: Yup.string()
        .trim()
        .matches(
          /^[a-zA-Z0-9]+$/,
          intl.formatMessage({
            id: "AUTH.ORGANIZATIONCODE.ERRORSYNTAX_FIELD",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.ORGANIZATIONCODE.REQUIRED_FIELD",
          })
        )
      ,
    }),

    user: Yup.object().shape({
      firstName: Yup.string()

        .max(
          50,
          intl.formatMessage({
            id: "MAXIMUM_50_SYMBOLS",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION_FIRSTNAME.REQUIRED_FIELD",
          })
        ),
      lastName: Yup.string()
        .max(
          50,
          intl.formatMessage({
            id: "MAXIMUM_50_SYMBOLS",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION_LASTNAME.REQUIRED_FIELD",
          })
        ),
      username: Yup.string()
        // .min(3, "Minimum 3 symbols")
        .max(
          50,
          intl.formatMessage({
            id: "MAXIMUM_50_SYMBOLS",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_USERNAME_FIELD",
          })
        ),
      tel: Yup.string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          intl.formatMessage({
            id: "AUTH.VALIDATION.TELE_FIELD",
          })
        )
        .min(
          10,
          intl.formatMessage({
            id: "MIXIMUM_10_SYMBOLS",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.TEL_FIELD",
          })
        ),

      email: Yup.string()
        .min(
          3,
          intl.formatMessage({
            id: "MINIMUM_3_SYMBOLS",
          })
        )
        .max(
          50,
          intl.formatMessage({
            id: "MAXIMUM_50_SYMBOLS",
          })
        )
        .email(
          intl.formatMessage({
            id: "AUTH.VALIDATION.EMAIL_FIELD",
          })
        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION_EMAIL_FIELD",
          })
        ),
      password: Yup.string()
        .matches(
          /(?=.*?[A-Z]).{6,}/,
          intl.formatMessage({
            id: "AUTH.VALIDATION.PASS_FIELD",
          })

        )
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION_PASSWORD_FIELD",
          })
        ),
      confirmPassword: Yup.string()
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION_PASSWORD_FIELD",
          })
        )
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            intl.formatMessage({
              id: "AUTH.VALIDATION_CONFIRMPASSWORD_FIELD",
            })
          ),
        }),
      isAcceptedTerms: Yup.bool().required(
        // "You must accept the terms and conditions"
        intl.formatMessage({
          id: "AUTH.VALIDATION_ACCEPTEDTERMS_FIELD",
        })
      ),
    }),
  });
  // const stateChanged = state => {
  //   // update the internal state using the updated state from the form field
  //   this.setState({
  //     password: state.value,
  //     strength: zxcvbn(state.value).score
  //   }, () => this.props.onStateChanged(state));
  // };
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (
      getPropByString(formik.touched, fieldname) &&
      getPropByString(formik.errors, fieldname)
    ) {
      return "is-invalid";
    }
    if (
      getPropByString(formik.touched, fieldname) &&
      !getPropByString(formik.errors, fieldname)
    ) {
      return "is-valid";
    }

    return "";
  };

  function getPropByString(obj, propString) {
    if (!propString) return obj;

    var prop,
      props = propString.split(".");
    //console.log("props: ", props)
    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];
      //console.log("prop: ", prop)
      var candidate = obj[prop];
      //console.log("obj: ", obj)
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  }

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      if (!recaptchaValid) {
        refRecaptcha.current.execute();
      } else {
        register(values.organization, values.user)
          .then(({ data: { accessToken } }) => {
            props.register(accessToken);
            disableLoading();
            setSubmitting(false);
            setSuccessFulDialog(true);
          })
          .catch((error) => {
            if (error?.response && error?.response.data) {
              error.clientMessage = error?.response?.data?.code;
            } else
              setError(
                (error.response &&
                  error.response.data &&
                  error.response.data.code) ||
                "errors"
              );
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
            setSubmitting(false);
            setRecaptchaValid(false);
          });
      }
    },
  });

  const onResolved = () => {
    disableLoading();
    setRecaptchaValid(true);
    formik.submitForm();
  }

  const history = useHistory();
  const [seconds, setSeconds] = useState(30);

  useDidMountEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("BOOOOM!");
      setSuccessFulDialog(false);
      history.push('/dang-nhap')
    }
  }, [isSuccessFulDialog, seconds]);

  return (
    <>
      {!isGoSignIn ? (
        <>
          <div
            style={{
              height: "300px",
              backgroundImage: 'url("/media/users/BG_Register.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          <div
            className="login-form login-signup pb-20 bg-white"
            style={{ zIndex: 1 }}
          >
            <div
              className="w-lg-800px bg-body rounded shadow-sm mx-auto overflow-auto bg-white"
              style={{ marginTop: "-95px" }}
            >
              <InformationRes title="Quy định" content="Quy định" />
              {error && (
                <ErrorMessages
                  type="danger"
                  message={
                    error && error.response
                      ? error.response.code
                      : "COMMONS.UI.Notification.Danger"
                  }
                  icon="flaticon-danger text-danger"
                />
              )}
              <form
                className="form p-10 p-lg-15"
                onSubmit={formik.handleSubmit}
              >
                <div className="mb-5 text-center">
                  <h1 className="text-dark mb-3">
                    <FormattedMessage id="AUTH.REGISTER.TITLE" />
                  </h1>
                  <div className="text-gray-400 fw-bold fs-4">
                    Bạn đã có tài khoản rồi?
                    <Link
                      to="/dang-nhap"
                      className="link-primary fw-bolder ml-3"
                    >
                      Đăng nhập ngay!
                    </Link>
                  </div>
                </div>
                {/* <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img alt="Logo" src="/media/svg/brand-logos/google-icon.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Google</a>
            <a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
              <img alt="Logo" src="/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3 mr-3" />Đăng nhập bằng Facebook</a> */}
                <h3 className="mt-5 text-uppercase text-primary">
                  <span className="h6">THÔNG TIN TỔ CHỨC</span>
                </h3>
                <div className=" row col-lg-2">
                  <div className="col-lg-6 mb-5 border border-primary border-2 bg-primary "></div>
                </div>
                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Tên tổ chức <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập tên tổ chức"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "organization.name"
                        )}`}
                        name=" "
                        autocomplete="off"
                        {...formik.getFieldProps("organization.name")}
                      />
                      <span>
                        <i className="fas flaticon-home icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.name &&
                      formik.errors.organization.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.organization.name}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mã số thuế <span className="required"></span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập mã số thuế"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "organization.taxNumber"
                        )}`}
                        name="organization.taxNumber"
                        autocomplete="off"
                        {...formik.getFieldProps("organization.taxNumber")}
                      />
                      <span>
                        <i className="fas flaticon-book icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.taxNumber &&
                      formik.errors.organization.taxNumber ? (
                      <div className="fv-plugins-message-container">
                        {/* <div className="fv-help-block">{formik.errors.organization.taxNumber}</div> */}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row fv-row mb-7">
                  <div className="col-xl-12">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Địa chỉ <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập địa chỉ"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "organization.address"
                        )}`}
                        name="organization.address"
                        autocomplete="off"
                        {...formik.getFieldProps("organization.address")}
                      />

                      <span>
                        <i className="fas flaticon-placeholder icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.address &&
                      formik.errors.organization.address ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.organization.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row fv-row mb-7">
                  <div className="col-xl-12">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Mã tổ chức<span className="required">*</span>
                    </label>
                    <div className="input-group">

                      <input
                        type="text"
                        name="organization.code"
                        autoComplete="off"
                        {...formik.getFieldProps("organization.code")}
                        className={`form-control  ${getInputClasses(
                          "organization.code"
                        )}`}
                        placeholder="Mã tổ chức"
                        aria-describedby="basic-addon2"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          .chuoicanho.com
                        </span>
                      </div>
                    </div>
                    {formik.touched.organization &&
                      formik.errors.organization &&
                      formik.touched.organization.code &&
                      formik.errors.organization.code ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.organization.code}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {isSuccessFulDialog && (
                  <SuccessfulDialog
                    seconds={seconds}
                    style={{ zIndex: 9999 }}
                    show={isSuccessFulDialog}
                    onHide={() => {
                      setSuccessFulDialog(false);
                      history.push('/dang-nhap')
                    }}
                  />
                )}
                <h3 className="mt-5 text-uppercase text-primary">
                  <span className="h6">THÔNG TIN CÁ NHÂN</span>
                </h3>
                <div className=" row col-lg-2">
                  <div className="col-lg-6 mb-5 border border-primary border-2 bg-primary "></div>
                </div>
                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Họ <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập họ"
                        type="text"
                        className={`form-control form-control-md  ${getInputClasses(
                          "user.firstName"
                        )}`}
                        name="user.firstName"
                        autocomplete="off"
                        {...formik.getFieldProps("user.firstName")}
                      />
                      <span>
                        <i className="fas flaticon-user icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.firstName &&
                      formik.errors.user.firstName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.user.firstName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Tên <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập tên"
                        type="text"
                        className={`form-control form-control-md ${getInputClasses(
                          "user.lastName"
                        )}`}
                        name="user.lastName"
                        autocomplete="off"
                        {...formik.getFieldProps("user.lastName")}
                      />
                      <span>
                        <i className="fas flaticon-user icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.lastName &&
                      formik.errors.user.lastName ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.user.lastName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Giới tính <span className="required"></span>
                    </label>
                    <div className="input-icon">
                      <select
                        className={`form-control form-control-md ${getInputClasses(
                          "gender"
                        )}`}
                        displayEmpty
                        name="user.gender"
                        onChange={(e) => formik.handleChange(e)}
                      >
                        <option value={0}>-- Chọn --</option>
                        <option value={1}>Nam</option>
                        <option value={2}>Nữ</option>
                      </select>
                      <span>
                        <i className="flaticon-users icon-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.gender &&
                      formik.errors.user.gender ? (
                      <div className="fv-plugins-message-container">
                        {/* <div className="fv-help-block">{formik.errors.user.gender}</div> */}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Ngày sinh <span className="required"></span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Ngày sinh"
                        type="date"
                        className={`form-control form-control-md  ${getInputClasses(
                          "birthday"
                        )}`}
                        name="user.birthday"
                        autocomplete="off"
                        {...formik.getFieldProps("user.birthday")}
                      />

                      <span>
                        <i className="fas flaticon-calendar con-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.birthday &&
                      formik.errors.user.birthday ? (
                      <div className="fv-plugins-message-container">
                        {/* <div className="fv-help-block">{formik.errors.user.birthday}</div> */}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row fv-row mb-7">
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Email <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập email"
                        type="email"
                        className={`form-control form-control-md ${getInputClasses(
                          "user.email"
                        )}`}
                        name="user.email"
                        autocomplete="off"
                        {...formik.getFieldProps("user.email")}
                      />
                      <span>
                        <i className="fas flaticon-email con-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.email &&
                      formik.errors.user.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.user.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xl-6">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Số điện thoại <span className="required">*</span>
                    </label>
                    <div className="input-icon">
                      <input
                        placeholder="Nhập số điện thoại"
                        type="tel"
                        className={`form-control form-control-md ${getInputClasses(
                          "user.tel"
                        )}`}
                        name="user.tel"
                        autocomplete="off"
                        {...formik.getFieldProps("user.tel")}
                      />
                      <span>
                        <i className="fas flaticon-support con-md text-primary"></i>
                      </span>
                    </div>
                    {formik.touched.user &&
                      formik.errors.user &&
                      formik.touched.user.tel &&
                      formik.errors.user.tel ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.user.tel}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <h3 className="mt-5 text-uppercase text-primary">
                  <span className="h6">TÀI KHOẢN</span>
                </h3>
                <div className=" row col-lg-2">
                  <div className="col-lg-6 mb-5 border border-primary border-2 bg-primary "></div>
                </div>
                <div className="fv-row mb-7">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Tên đăng nhập <span className="required">*</span>
                  </label>
                  <div className="input-icon">
                    <input
                      placeholder="Tên đăng nhập"
                      type="username"
                      className={`form-control form-control-md ${getInputClasses(
                        "user.username"
                      )}`}
                      name="user.username"
                      autocomplete="off"
                      {...formik.getFieldProps("user.username")}
                    />
                    <span>
                      <i className="fas fa-address-card icon-md text-primary"></i>
                    </span>
                  </div>
                  {formik.touched.user &&
                    formik.errors.user &&
                    formik.touched.user.username &&
                    formik.errors.user.username ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.user.username}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="fv-row mb-7 " data-kt-password-meter="true">
                  <div className="mb-1">
                    <label className="form-label fw-bolder text-dark fs-6">
                      Mật khẩu <span className="required">*</span>
                    </label>
                    <div className="position-relative mb-3">
                      <div className="input-icon mb-5">
                        <input
                          placeholder="Nhập mật khẩu"
                          type={!showPass ? "password" : "text"}
                          className={`form-control form-control-md ${getInputClasses(
                            "user.password"
                          )}`}
                          name="user.password"
                          autocomplete="off"
                          {...formik.getFieldProps("user.password")}
                        />
                        <span>
                          <i className="fas fa-key icon-md text-primary"></i>
                        </span>
                      </div>

                      {formik.touched.user &&
                        formik.errors.user &&
                        formik.touched.user.password &&
                        formik.errors.user.password ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block mb-5">
                            {formik.errors.user.password}
                          </div>
                        </div>
                      ) : null}
                      <input
                        type="checkbox"
                        checked={showPass}
                        onClick={(e) => setShowPass(!showPass)}
                      />
                      <label className="form-label fw-bolder text-dark fs-6 ml-2 mr-3">
                        Hiển thị mật khẩu{" "}
                      </label>

                      <span
                        className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                        data-kt-password-meter-control="visibility"
                      >
                        <i className="bi bi-eye-slash fs-2"></i>
                        <i className="bi bi-eye fs-2 d-none"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label fw-bolder text-dark fs-6">
                    Xác nhận mật khẩu <span className="required">*</span>
                  </label>
                  <div className="input-icon">
                    <input
                      placeholder="Nhập mật khẩu xác nhận"
                      type="password"
                      className={`form-control form-control-md ${getInputClasses(
                        "user.confirmPassword"
                      )}`}
                      name="user.confirmPassword"
                      autocomplete="off"
                      {...formik.getFieldProps("user.confirmPassword")}
                    />
                    <span>
                      <i className="fas flaticon-lock con-md text-primary"></i>
                    </span>
                  </div>
                  {formik.touched.user &&
                    formik.errors.user &&
                    formik.touched.user.confirmPassword &&
                    formik.errors.user.confirmPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.user.confirmPassword}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="fv-row mb-10">
                  <label className="form-check form-check-custom form-check-solid form-check-inline">
                    <input
                      type="checkbox"
                      name="user.isAcceptedTerms"
                      className="form-check-input"
                      {...formik.getFieldProps("user.isAcceptedTerms")}
                    />
                    <span>
                      Tồi đồng ý với &nbsp;
                      <a
                        href="https://chuoicanho.com/dieu-khoan-su-dung/"
                        target="_blank"
                        className="form-check-label fw-bold text-gray-700 fs-6 mr-3"
                        rel="noopener noreferrer"
                      >
                        Điều khoản sử dụng
                      </a>
                      và &nbsp;
                      <a
                        href="https://chuoicanho.com/chinh-sach-bao-mat/"
                        target="_blank"
                        className="form-check-label fw-bold text-gray-700 fs-6 mr-3"
                        rel="noopener noreferrer"
                      >
                        Điều khoản bảo mật
                      </a>
                    </span>

                    <span />
                  </label>
                  {formik.touched.user &&
                    formik.errors.user &&
                    formik.touched.user.isAcceptedTerms &&
                    formik.errors.user.isAcceptedTerms ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.user.isAcceptedTerms}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      !formik.values.user.isAcceptedTerms
                    }
                    className={`btn btn-lg btn-primary w-100 mb-5`}
                  >
                    <span className="indicator-label">Đăng ký</span>
                    {loading && (
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle ms-2 ml-3"></span>
                      </span>
                    )}
                  </button>
                  <Link to="/dang-nhap">Huỷ bỏ</Link>
                </div>
                <Recaptcha
                  onResolved={onResolved}
                  ref={refRecaptcha}
                  sitekey="6Lcsg_4dAAAAAPo_yPTuYnTfihPC9GCyg9FKbPr3"
                />
              </form>
            </div>
          </div>
        </>
      ) : (
        <Redirect to="/dang-nhap" />
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
