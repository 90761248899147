import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

function SuccessfulDialog(props) {
  const { onHide, show, seconds } = props;
  return (
    <>
      <Modal show={show} onHide={onHide} seconds={seconds}>
        <div className="">
          <div
            className="flaticon-circle text-right text-primary pr-5 pt-3 pb-3 "
            onClick={onHide}
          >
          </div>

          <div className="w-lg-500px bg-white rounded shadow-sm p-10 mx-auto text-center">
            <i className="flaticon-interface-5 icon-2x text-primary"></i>
            <span className="text-primary ml-3 h2 pb-30">
              ĐĂNG KÝ THÀNH CÔNG{" "}
            </span>
            <p className="pt-5 pb-5">
              Sau <span className="fw-bold text-danger h4">{seconds}</span> giây
              hệ thống sẽ tự động
              <br />
              chuyển về trang đăng nhập{" "}
            </p>
            <div className="h5 pb-5">
              <p className="">
                Để được hỗ trợ và truy cập nhanh nhất vui lòng liên hệ{" "}
              </p>
            </div>
            <div className="pb-5">
              <span className="pt-5">
                <a className="h1 text-primary" href="tel:0931741666">
                  <i className="fas fa-phone-alt h1 text-primary"></i>{" "}
                  0931 741 666
                </a>
              </span>
            </div>
            <div className="h5 pb-3">
              {" "}
              hoặc{" "}
              <span className="text-primary ">
                <a
                  className="text-primary"
                  href="mailto:chuoicanho@bloomgoo.vn"
                >
                  chuoicanho@bloomgoo.vn
                </a>
              </span>
            </div>

            {/* <Link
              to="/dang-nhap"
              className={`btn btn-lg btn-primary w-50 mb-5 `}
            >
              Đăng nhập ngay
            </Link> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(SuccessfulDialog));
