import React from "react";
import NumberFormat from 'react-number-format';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (size, touched, errors, touchedArray, errorsArray, indexArray, nameChildren) => {
  const classes = ["form-control", `form-control-${size}`, "money-field", "text-right"];

  if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren]) {
    classes.push("is-invalid");
  }

  if (touchedArray && touchedArray[indexArray] && touchedArray[indexArray][nameChildren] && !(errorsArray && errorsArray[indexArray] && errorsArray[indexArray][nameChildren])) {
    classes.push("custom-is-valid");
  }

  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }
  return classes.join(" ");
};

export function MoneyField({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  allowNegative = true,
  className = '',
  withFeedbackLabel = false,
  formProps,
  required = false,
  customFeedbackLabel,
  size = "md",
  type = "number",
  ...props
}) {

  let nameArray = "";
  let nameChildren = "";
  let indexArray;
  if (field.name.includes('].')) {
    const str = field.name;
    let positionPrefix = str.indexOf('[');
    let positionSuffixes = str.indexOf(']');
    nameArray = str.slice(0, positionPrefix);
    nameChildren = str.slice(positionSuffixes + 2);
    indexArray = str.slice(positionPrefix + 1, positionSuffixes);
  }

  return (
    <>
      {label && <label>{label} {required && <span className="required">*</span>}</label>}
      {type === 'number' && (
        <NumberFormat
          thousandSeparator={true}
          allowNegative={allowNegative}  /// cho phép số âm
          className={`${getFieldCSSClasses(size, touched[field.name], errors[field.name], touched[nameArray], errors[nameArray], indexArray, nameChildren)} ${className}`}
          style={{
            minWidth: "100px"
          }}
          {...field}
          {...props}
          value={field.value ? field.value : (field.value === 0 ? 0 : "")}
        />

      )}

      {false && withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
