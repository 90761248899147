import React from "react";
import moment from "moment";
import { STRING_DEFAULT_LABEL } from "../../app/_helpers/EnumUIHelpers";
import { parseDomain } from "parse-domain";

export function stringToSlug(str) {
  var from =
    "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, "_")
    .replace(/-+/g, "_");

  return str.toUpperCase();
}

export const getDomain = () => {
  const parseResult = parseDomain(window.location.host.split(":")[0]);
  const { subDomains } = parseResult;
  const code =
    subDomains === undefined || subDomains === null || subDomains.length === 0
      ? "chuoicanho"
      : subDomains[subDomains.length - 1];
  return code;
};

export const DatetimeColumnFormatter = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("HH:mm:ss DD-MM-YYYY") : "";
};

export const DaytimeColumnFormatter = (cellContent, row) => {
  return cellContent ? moment(cellContent).format("DD-MM-YYYY") : "";
};

export const DayHasTimeColumnFormatter = (cellContent, row) => {
  const parsedDate = moment(cellContent, 'HH:mm DD/MM/YYYY');
  return cellContent ? parsedDate.format("DD/MM/YYYY") : "";
};

export const ConvertTimestampToDate = (timestamp) => {
  var date = new Date(timestamp ? timestamp : "");
  return date ? moment(date).format(" DD-MM-YYYY") : "";
};

export const SlashTimestampToDate = (timestamp) => {
  var date = new Date(timestamp ? timestamp : "");
  return date ? moment(date).format(" DD/MM/YYYY") : "";
};

export const ConvertDateToTimestamp = (timeDate) => {
  const timestamp = Date.parse(timeDate);
  return timestamp;
};

export const ImageColumnFormatter = (cellContent, row) =>
  cellContent ? (
    <>""</>
  ) : (
    <>
      <div
        id="kt_profile_avatar"
        className="image-input image-input-outline imagebackground"
      >
        <div className="image-input-wrapper"></div>
      </div>
    </>
  );
export function removeMoneyCommas(str) {
  if (typeof str === "number") {
    str = str.toString().replace(/,/g, "");
  }
  if (typeof str === "string") {
    str = str.replace(/,/g, "");
  }
  if (str === null) {
    str = "0";
  }
  if (str === undefined) {
    str = "0";
  }
  return Number(str || "0");
}

export function thisDay(date) {
  var today = date ? new Date(date) : new Date();
  var thisDay =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    today.getDate().toString().padStart(2, "0");
  return thisDay;
}

export function thisMonth(date) {
  let today = date ? new Date(date) : new Date();
  let thisDay =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0");
  return thisDay;
}

export function firstDayThisMonth(date) {
  var today = date ? new Date(date) : new Date();
  var thisDay =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    (1).toString().padStart(2, "0");
  return thisDay;
}

export function startDate(valueSelect) {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var month = firstDay.getMonth() + 1;
  var firstDayOfMonth =
    firstDay.getDate().toString().padStart(2, "0") +
    "/" +
    (firstDay.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    firstDay.getFullYear();

  if (month <= 3) month = 1;
  else if (month <= 6) month = 4;
  else if (month <= 9) month = 7;
  else month = 10;

  var firstDayOfQuarterYear =
    "01/" + month.toString().padStart(2, "0") + "/" + firstDay.getFullYear();
  var firstDayOfYear = "01/01/" + firstDay.getFullYear();

  if (valueSelect === 1) return firstDayOfMonth;
  if (valueSelect === 2) return firstDayOfQuarterYear;
  if (valueSelect === 3) return firstDayOfYear;
}

export function endDate(valueSelect) {
  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var month = lastDay.getMonth() + 1;
  var day = 30;
  var lastDayOfMonth =
    lastDay.getDate() +
    "/" +
    (lastDay.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    lastDay.getFullYear();

  if (month <= 3) {
    month = 3;
    day = 31;
  } else if (month <= 6) month = 6;
  else if (month <= 9) month = 9;
  else {
    month = 12;
    day = 31;
  }

  var lastDayOfQuarterYear =
    day + "/" + month.toString().padStart(2, "0") + "/" + lastDay.getFullYear();
  var lastDayOfYear = "31/12/" + lastDay.getFullYear();
  if (valueSelect === 1) return lastDayOfMonth;
  if (valueSelect === 2) return lastDayOfQuarterYear;
  if (valueSelect === 3) return lastDayOfYear;
}

export function getStringDateNow() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  return `${year.toString().padStart(4, "0")}-${month
    .toString()
    .padStart(2, "0")}-${day.toString().padStart(2, "0")}T${hour
      .toString()
      .padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
}

export function getStringDay(date) {
  var strDay =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");
  return strDay;
}

export function getStartMonthYear() {
  var year = new Date().getFullYear();
  return year + "-01";
}

export function getEndMonthYear() {
  var year = new Date().getFullYear();
  return year + "-12";
}

export function dateSubmit(date) {
  date = date.split("/");
  return date[2] + "-" + date[1] + "-" + date[0];
}
export function convertTimestampToDateLocal(date) {
  if (date) {
    let d = new Date(date);
    let newDate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      d.getDate().toString().padStart(2, "0") +
      "T" +
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0");
    return newDate;
  } else {
  }
}

export function compareOverDay(endTime) {
  let thisDay = new Date().getTime();
  let overDay = endTime - thisDay;
  return overDay;
}

export function sumDateTime(currentDate, paymentType, duration) {
  let day = new Date(currentDate);
  let totalMonth =
    paymentType === 1
      ? Number(day.getMonth() + 1) + Number(duration)
      : paymentType === 2
        ? Number(duration) * 12
        : 0;
  let currentMonth = day.getMonth() + 1;
  let newMonth =
    paymentType === 1 ? (totalMonth % 12 ? totalMonth % 12 : 12) : currentMonth;
  let newYear = totalMonth / 12 ? Math.floor(totalMonth / 12) : 0;
  let newDate =
    Number(day.getFullYear() + Number(newYear)) +
    "-" +
    newMonth.toString().padStart(2, "0") +
    "-" +
    day.getDate().toString().padStart(2, "0") +
    "T" +
    day.getHours().toString().padStart(2, "0") +
    ":" +
    day.getMinutes().toString().padStart(2, "0");
  return newDate;
}

export function convertNumberToWords(str) {
  // đầu vào phải là số hoặc chuỗi số
  str = parseInt(str) + ""; //chuyển thành số nguyên
  var i = 0;
  var arr = [];
  var index = str.length;
  var u = "2 nghìn triệu tỉ".split(" ");
  var defaultNumber = " hai ba bốn năm sáu bảy tám chín";
  var currency = "đồng";
  var units = ("1 một" + defaultNumber).split(" ");
  var ch = "lẻ mười" + defaultNumber;
  var tr = "không một" + defaultNumber;
  var tram = tr.split(" "); //
  var chuc = ch.split(" ");
  var lastStr = "";
  function tenh(a) {
    //đọc khối 2 chữ số
    // a 48
    var sl1 = units[a[1]]; //số hàng đơn vị
    var sl2 = chuc[a[0]]; //số hàng chục
    var append = "";
    if (a[0] > 0 && a[1] == 5) {
      sl1 = "lăm";
    }
    if (a[0] > 1) {
      append = " mươi";
    }
    if (a[1] == 1 && a[0] != 1 && a[0] != 0) {
      sl1 = " mốt";
    }
    let str = sl2 + "" + append + " " + sl1;
    return str;
  }

  function blockOfThree(d) {
    // khối 3 chữ số

    var _a = d + "";
    let str = "";
    if (d === "000") {
      str = "";
    }
    if (_a.length) {
      if (_a.length === 0) {
        str = "";
      }
      if (_a.length === 1) {
        str = units[_a];
      }
      if (_a.length === 2) {
        str = tenh(_a);
      }
      if (_a.length === 3) {
        let sl12 = "";
        let sl3 = tram[_a[0]] + " trăm";
        if (_a.slice(1, 3) != "00") {
          sl12 = tenh(_a.slice(1, 3));
        }

        str = sl3 + " " + sl12;
      }
    }
    return str;
  }

  if (index == 0 || str == "NaN" || index >= 13) {
    lastStr = "";
  } else {
    while (index >= 0) {
      arr.push(str.substring(index, Math.max(index - 3, 0))); //tách ra thành khối 3 chữ số;
      index -= 3;
      //console.log(arr);
    }
    for (i = arr.length - 1; i >= 0; i--) {
      if (arr[i] != "" && arr[i] != "000") {
        lastStr += " " + blockOfThree(arr[i]) + " " + u[i];
      }
    }
    lastStr = (lastStr + " " + currency)
      .replace(/[0-9]/g, "")
      .replace(/  +/g, " ");
    if (lastStr == " đồng") lastStr = " không đồng";
  }

  lastStr = lastStr.charAt(1).toUpperCase() + lastStr.slice(2);
  //console.log("lastStr: ", lastStr)
  if (str) return lastStr;
  else return null;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function convertPercent(numerator, denominator) {
  if (denominator === 0 || denominator === "0") return 0;

  return (
    (numerator * 100) / denominator ? (numerator * 100) / denominator : 0
  ).toFixed(2);
}

export function convertPercentChart(numerator, denominator) {
  if (denominator === 0 || denominator === "0") return 0;

  return (
    (numerator * 100) / denominator ? (numerator * 100) / denominator : 0
  ).toFixed(1);
}

export function compareDate(dateBefore, dateAfter) {
  const comparetime = dateAfter.getTime() - dateBefore;
  console.log(dateBefore);
  if (comparetime < 1000 * 60) return Math.ceil(comparetime / 1000) + " giây";
  else if (comparetime < 1000 * 60 * 60)
    return Math.ceil(comparetime / (1000 * 60)) + " phút";
  else if (comparetime < 1000 * 60 * 60 * 60)
    return Math.ceil(comparetime / (1000 * 60 * 60)) + " giờ";
  else if (comparetime < 1000 * 60 * 60 * 60 * 24)
    return Math.ceil(comparetime / (1000 * 60 * 60 * 60)) + " ngày";
  // else return dateBefore && dateBefore.getDay()
}

export function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}

export function getNumberDateToDate(dateBefore, dateAfter) {
  let d1, d2;
  if (typeof dateBefore === "number") d1 = dateBefore;
  else if (typeof dateBefore === "string") d1 = Date.parse(dateBefore);
  else d1 = 0;

  if (typeof dateAfter === "number") d2 = dateAfter;
  else if (typeof dateAfter === "string") d2 = Date.parse(dateAfter);
  else d2 = 0;

  console.log("d2: ", d2 - d1);
  return (d2 - d1) / 86400000;
}

export function dataTableUndefined(data, className) {
  let state = {
    data: data,
    className: className,
  };
  if (data === null || data === undefined) {
    state.data = "---";
    state.className = "text-center";
  }

  return state;
}

export function convertOverText(content, maxCharacters) {
  if (content) {
    var str = `${content}`;
    var res = str.slice(0, maxCharacters); // Hello
    if (content.length > maxCharacters) {
      return <span>{res + "..."}</span>;
    } else return <span>{res}</span>;
  } else return <span></span>;
}

export function copyString(string) {
  var copyText = document.getElementById(`${string}`);
  var input = document.createElement("INPUT");
  document.body.appendChild(input);
  input.value = copyText.innerHTML;
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(input);
}

export function converValuePercent(number) {
  if (number > 100) {
    number = 100;
  }
}

export const getPropertiesObject = (values) => {
  const properties = [];
  for (const property in values) {
    properties.push(property);
  }

  return properties;
};

export const getValuePropertiesObject = (values) => {
  const valueProperties = [];
  for (const property in values) {
    valueProperties.push(values[property]);
  }

  return valueProperties;
};

export const convertHoursToDate = (hours) => {
  let str = "";
  if (hours === null || hours === undefined) {
    str = STRING_DEFAULT_LABEL;
  } else {
    if (hours < 24) {
      str = `${hours} giờ`;
    } else {
      let hour = hours % 24;
      let day = Math.floor(hours / 24);
      if (hour) {
        str = `${day} ngày ${hour} giờ`;
      } else {
        str = `${day} ngày`;
      }
    }
  }

  return str;
};
