import { createSlice } from "@reduxjs/toolkit";
import {
  ERROR_TYPES_OBJ,
  INVOICE_APPROVER_OBJ,
  INVOICE_PAYMENT_STATUS_OBJ,
} from "../../../../_helpers/_CommonUIHelpers";

const initialInvoicesState = {
  listLoading: null,
  actionsLoading: null,
  totalCount: 0,
  entities: [],
  totalCountEmail: 0,
  entitiesEmail: [],
  parents: [],
  invoiceImport: [],
  invoiceForEdit: null,
  invoiceForShow: null,
  invoicePreview: null,
  notification: null,
  entitiesExcluded: null,
  entitiesUnpaid: null,
  totalCountExcluded: null,
  totalCountUnpaid: null,
  entitiesOverPaid: null,
  totalCountOverPaid: null,
  entitiesPreExpired: null,
  totalCountPreExpired: null,
  filterCount: null,
  currentInvoice: null,
  currentInvoices: [],
  invoiceUnit: [],
  currentIds: [],
  errorType: null,
  error: null,

  invoicesByBuilding: [],
  isLoadingInvoicesByBuilding: false,
  statisticsInvoicesByBuilding: {},
  refresh: 0,

  refreshTable: 0,
};

export const callTypes = {
  table: "table",
  list: "list",
  action: "action",
  initialization: "initialization",
  reloadForm: "reloadForm",
  exitForm: "exitForm",
  export: "export",

  invoicesByBuilding: "invoicesByBuilding",
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: initialInvoicesState,
  reducers: {
    catchError: (state, action) => {
      const error = action.payload.error;
      state.error = {
        callType: error.callType,
        clientMessage: error.clientMessage,
        action: error.action,
        status: error.status,
        response: error.response,
      };
      if (action.payload.callType === callTypes.table) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.PREPARE; // loi khoi tao
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.export) {
        state.actionsLoading = false;
      } else if (action.payload.callType === callTypes.invoicesByBuilding) {
        state.isLoadingInvoicesByBuilding = false;
      } else {
        state.actionsLoading = false;
      }
    },

    refresh: (state, action) => {
      state.refresh += 1;
    },

    refreshTable: (state, action) => {
      state.refreshTable += 1;
    },

    notification: (state, action) => {
      state.notification = action.payload.notification;
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.initialization) {
        state.notification = null;
        state.actionsLoading = true;
        state.errorType = ERROR_TYPES_OBJ.START;
      } else if (action.payload.callType === callTypes.reloadForm) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.exitForm) {
        state.notification = null;
        state.errorType = ERROR_TYPES_OBJ.MIDDLE;
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.table) {
        state.listLoading = true;
        state.entities = [];
      } else if (action.payload.callType === callTypes.export) {
        state.actionsLoading = true;
      } else if (action.payload.callType === callTypes.invoicesByBuilding) {
        state.isLoadingInvoicesByBuilding = true;
        state.invoicesByBuilding = [];
        state.statisticsInvoicesByBuilding = {};
      } else {
      }
    },
    resetModule: (state, action) => {
      state.listLoading = null;
      state.actionsLoading = null;
      state.error = null;
      state.notification = null;
      state.currentInvoices = [];
      state.currentInvoices = [];
      state.currentInvoice = null;

      state.entities = [];
    },
    resetModal: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.error = null;
      state.invoiceForEdit = null;
    },
    resetModalPrint: (state, action) => {
      state.errorType = null;
      state.error = null;
      state.invoicePreview = null;
    },
    resetModalShow: (state, action) => {
      state.errorType = null;
      state.error = null;
      state.invoiceForShow = null;
    },
    resetIsNotClosingInvoices: (state, action) => {
      if (state.errorType !== ERROR_TYPES_OBJ.PREPARE) {
        state.error = null;
      }
      state.errorType = null;
      state.error = null;
      state.entitiesIsNotClosing = null;
      console.log("cleanEntitiesIsNotClosing");
    },

    resetCurrentInvoices: (state, action) => {
      state.currentInvoice = null;
      state.currentInvoices = [];
    },
    // getInvoiceById
    invoiceFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.invoiceForEdit = action.payload.invoiceForEdit;
      state.currentInvoice = null;
      state.currentInvoices = [];
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE; // da vao dc la 2
    },

    invoicesByBuildingIdGetted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.isLoadingInvoicesByBuilding = false;
      state.invoicesByBuilding = action.payload.invoices;
      state.statisticsInvoicesByBuilding = action.payload.statistic;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },

    //show
    invoiceShowFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.invoiceForShow = action.payload.invoiceForShow;
      //state.error.type = 2; //cho vao
    },
    // receipt /invoice Table
    invoicesUnpaid: (state, action) => {
      const { totalCountUnpaid, entitiesUnpaid } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesUnpaid = entitiesUnpaid;
      state.totalCountUnpaid = totalCountUnpaid;
    },
    //   payment / invoice Table
    invoicesOverPaid: (state, action) => {
      const { totalCountOverPaid, entitiesOverPaid } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesOverPaid = entitiesOverPaid;
      state.totalCountOverPaid = totalCountOverPaid;
    },

    invoicesFetchedByContractId: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesByContractId = entities;
    },
    // findAllInvoices

    invoicesIsNotClosingFetched: (state, action) => {
      const { entities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.entitiesIsNotClosing = entities;
    },

    invoicesFetchedAll: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findInvoices
    invoicesFetched: (state, action) => {
      const { totalCount, entities, filterCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount = filterCount;
    },

    invoicesByUnitIdsFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.entities = action.payload.invoices;
    },

    invoicesPreExpiredFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.filterCount.totalPreExpired = totalCount;
    },

    invoicesExport: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },

    invoicesFetchedByUnit: (state, action) => {
      const { entities } = action.payload;
      state.invoicesOfUnit = entities;
    },
    // findParentInvoices
    invoicesParentFetched: (state, action) => {
      const { totalCount, parents } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.parents = parents;
      state.totalCount = totalCount;
    },
    // createInvoice
    invoiceCreated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.entities.unshift(action.payload.invoice);
      state.currentInvoices.push(action.payload.invoice);
    },
    // updateInvoice
    invoiceUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.invoice.id) {
          return action.payload.invoice;
        }
        return entity;
      });
    },
    // deleteInvoice
    invoiceDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
      state.currentIds = [];
      state.currentInvoices = [];
    },
    // deleteInvoices
    invoicesDeleted: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.error = null;
      state.actionsLoading = false;
      state.errorType = ERROR_TYPES_OBJ.END;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // invoicesUpdateState
    invoicesStatusUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
          if (entity.totalReceivableMoney === 0) {
            if (status === INVOICE_APPROVER_OBJ.APPROVAL) {
              entity.paymentStatus = INVOICE_PAYMENT_STATUS_OBJ.PAID;
            } else {
              entity.paymentStatus = INVOICE_PAYMENT_STATUS_OBJ.UNPAID;
            }
          }
        }
        return entity;
      });
      state.currentIds = ids;
    },

    //invoicesUpdateState
    invoicesClosingDebtsUpdated: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      const { ids, isClosingDebt } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.isClosingDebts = isClosingDebt;
        }
        return entity;
      });
      state.currentIds = ids;
    },

    invoiceIsSented: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.id) {
          entity.isSent = action.payload.isSentStatus;
        }
        return entity;
      });
    },

    invoicesPreview: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.errorType = null;
      state.invoicePreview = action.payload.invoicePreview;
      state.currentIds = [];
    },
    createInvoicesByUnitIds: (state, action) => {
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
    },

    invoiceEmailsFetched: (state, action) => {
      const { totalCountEmail, entitiesEmail } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesEmail = entitiesEmail;
      state.totalCountEmail = totalCountEmail;
    },

    invoiceEmailFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
      state.actionsLoading = false;
      state.invoiceEmailForEdit = action.payload.invoiceEmailForEdit;
      state.error = null;
      state.errorType = ERROR_TYPES_OBJ.MIDDLE;
    },

    invoiceEmailSaved: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
    },

    resetInvoiceImport: (state, action) => {
      state.invoiceImport = null;
    },

    invoiceImportFetched: (state, action) => {
      const { invoiceImport } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.invoiceImport = invoiceImport;
    },

    invoiceImportStoreFetched: (state, action) => {
      state.errorType = ERROR_TYPES_OBJ.END;
      state.actionsLoading = false;
      state.error = null;
      state.error = null;
      state.invoiceImport = [];
    },
  },
});
